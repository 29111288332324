<template>
  <Card v-if="processIsValidated">
    <template #title>
      {{ $t("video.verify.title") }}
    </template>
    <template #subtitle>
      {{ $t("video.verify.description") }}
    </template>
    <template #content>
      <video-card-body :process="process" />
    </template>
    <template #footer>
      <div class="p-grid p-nogutter p-justify-between">
        <i />
        <Button
          :label="nextStepLabel"
          icon-pos="right"
          icon="pi pi-angle-right"
          @click="nextStep"
        />
      </div>
    </template>
  </Card>
  <Card v-else>
    <template #title>
      {{ $t("video.verify.title_loading") }}
    </template>
    <template #subtitle>
      {{ $t("video.verify.description_loading") }}
    </template>
    <template #content>
      <div class="p-d-flex p-jc-center">
        <div class="p-mt-3 p-mb-3">
          <ProgressSpinner />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { computed } from "@vue/reactivity";
import { onBeforeUnmount, onMounted } from "@vue/runtime-core";
import Button from "primevue/button";
import Card from "primevue/card";
import ProgressSpinner from "primevue/progressspinner";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import {
  FETCHING_DELAY,
  TOAST_LIFE,
  VIDEO_PROCESS_FETCHING,
} from "../../config";
import { PROCESS_STATUS } from "../../models/ProcessStatus";
import { sleepFor } from "../../service/utils";
import { FETCHING_PROCESS_BY_ID } from "../../store/modules/process/process.actions.type";
import { GET_PROCESS_BY_ID } from "../../store/modules/process/process.getters.type";
import VideoCardBody from "./VideoCardBody";

export default {
  name: "StepVideoVerify",
  components: {
    Button,
    Card,
    ProgressSpinner,
    VideoCardBody,
  },
  props: {
    processId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const process = computed(() =>
      store.getters[GET_PROCESS_BY_ID](props.processId)
    );
    const processIsValidated = computed(
      () => process.value?.status === PROCESS_STATUS.VALIDATED
    );
    const nextStepLabel = t("common.continue");
    let refreshTimer;

    onMounted(() => {
      if (process.value === undefined) {
        clearTimeout(refreshTimer);
        router.push({ name: "upload-video" });
      }

      if (!processIsValidated.value) {
        autoRefresh();
      }
    });

    async function autoRefresh() {
      await sleepFor(FETCHING_DELAY);
      refreshTimer = setInterval(async () => {
        await store.dispatch(FETCHING_PROCESS_BY_ID, props.processId);
        if (processIsValidated.value) {
          await toast.add({
            severity: "info",
            summary: t("common.info"),
            detail: t("video.verify.toast_success"),
            life: TOAST_LIFE,
          });
          clearTimeout(refreshTimer);
        }
      }, VIDEO_PROCESS_FETCHING);
    }

    onBeforeUnmount(() => {
      clearTimeout(refreshTimer);
    });

    function nextStep() {
      router.push({
        name: "payment-video",
        params: { processId: props.processId },
      });
    }

    return {
      process,
      processIsValidated,
      nextStep,
      nextStepLabel,
    };
  },
};
</script>

<style scoped>
.process-figure img {
  max-width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-card {
  padding-top: 0;
}
</style>
